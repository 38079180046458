/**
  * cForm
*/

const Utils = require('./utils.js');

/* global grecaptcha */

(function($, window, document, undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  const cForm = {

    formInit() {
      const cform = $('form.x5-cform');

      if( !cform.length ) {
        return;
      }

      this.recaptchaScript(cform);

      for(let i=0; i<cform.length; i++) {
        this.dateFields(cform[i]);
        this.submitForm(cform[i]);
      }
    },

    recaptchaScript(cform) {
      const hasRecaptcha = cform.find('.g-recaptcha');

      if (hasRecaptcha.length) {
        const script = document.createElement('script');
        script.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://www.google.com/recaptcha/api.js';
        script.type = 'text/javascript';
        script.async = 'true';
        script.defer = 'true';

        document.head.appendChild(script);
      }
    },

    dateFields(form) {
      const dateFields = $(form).find('.c-form__date-field, .c-form__datetime-field');

      if (!dateFields ) {
        return;
      }

      $.each(dateFields, (i, el) => {
        const dateField = $(el),
          hasTime = dateField.hasClass('c-form__datetime-field'),
          time24h = dateField.attr('data-time-hr');

        dateField.flatpickr({
          enableTime: hasTime,
          time_24hr: time24h == '24',
          onOpen: (selecetdDate, dateStr, instance) => {
            const calendar = $(instance.calendarContainer);

            if( hasTime ) {
              calendar.addClass('showTimeInput');
            }
          }
        });
      });
    },

    submitForm(cform) {
      const form  = $(cform),
        submit_action = form.attr('data-submit-action') || 'message',
        loader    = form.find('.cform__loader'),
        submitBtn = form.find('input[type=submit]'),
        message   = form.find('.c-field__message'),
        errorText = form.find('.c-field__error');

      let sending = false;

      form.on('submit', (e) => {
        e.preventDefault();

        if( sending == true ) {
          return;
        }

        loader.addClass('show');
        sending = true;
        submitBtn.attr('disabled', true);
        message.removeClass('show error success').text('');
        errorText.removeClass('show').text('');

        const formData = new FormData(cform);

        const checkboxesRadios = $(form).find('input[type=checkbox], input[type=radio]');
        const recaptchaField = $(form).find('textarea[id="g-recaptcha-response"]');

        $.each(checkboxesRadios, (i, field) => {
          let formField = $(field),
            //type = formField.attr('type'),
            name  = formField.attr('name');

          if( !formField.is(':checked') ) {
            formData.append(name, []);
          }
        });
        formData.append('action', 'x5_cform_submit');
        formData.append('nonce', Utils.localized('cform_nonce'));
        formData.append('form_name', form.attr('name'));

        if (formData.has('g-recaptcha-response') && recaptchaField.length) {
          formData.set('g-recaptcha-response', recaptchaField.val());
        }

        $.ajax({
          url: Utils.localized('ajaxurl'),
          type: 'post',
          contentType: false,
          processData: false,
          data: formData,
          success: function (response) {
            switch(response[0]) { // eslint-disable-line no-magic-numbers
            case 'ok':
              if( response[1] ) { // eslint-disable-line no-magic-numbers
                if (submit_action == 'redirect') {
                  const form_page_url = response[2];

                  window.location.replace(form_page_url);
                }else {
                  form.find('.c-field__message').text(response[1]).addClass('show success'); // eslint-disable-line no-magic-numbers
                }
              }

              $.each(form.find('input, textarea'), (i, el) => {
                const field = $(el),
                  type = field.attr('type');

                if( type != 'submit' && type != 'checkbox' && type != 'radio' && type != 'acceptance' && submit_action != 'redirect' ) {
                  field.val('');
                }
              });

              form.trigger('cform:sent', 'ok');
              break;
            case 'error':
              const errors = response[1]; // eslint-disable-line no-magic-numbers

              if( errors ) {
                $.each(errors, (name, error) => {
                  const field = form.find(`[name="${name}"], [name="${name}[]"]`);
                  if( error[0] ) { // eslint-disable-line no-magic-numbers
                    field.closest('.c-form-group').find('.c-field__error').text(error[0]).addClass('show'); // eslint-disable-line no-magic-numbers
                  }
                });

                if( response[2] ) { // eslint-disable-line no-magic-numbers
                  form.find('.c-field__message').text(response[2]).addClass('show error'); // eslint-disable-line no-magic-numbers
                }

                if ( recaptchaField.length && typeof grecaptcha !== 'undefined' ) {
                  grecaptcha.reset();
                }

                form.trigger('cform:not_sent', 'error');
              }
              break;
            }
            loader.removeClass('show');
            sending = false;
            submitBtn.attr('disabled', false);
          },
          error: function (response) {
            console.log('error', response);
            form.trigger('cform:not_sent', response);
          }
        });

      })
    },

    init() {
      $(document).ready( () => {
        this.formInit();
      });

      $(window).on('resize', () => {

      });
    }
  };

  module.exports = cForm;

})(jQuery, window, document, undefined);
